<template>
  <main>
    <div class="mb-5 h-65 b-b b-grey-80 text-center container">
      <p class="pb-6 pt-16 fw-medium">
        {{ $t.maintenance.ERR5xx.message }}
      </p>
      <base-button class="underlined" @click="clearError()">
        {{ $t.maintenance.ERR5xx.cta }}
      </base-button>
    </div>
  </main>
</template>
