<template>
  <main>
    <div class="mb-5 h-65 b-b b-grey-80 text-center container">
      <p class="pb-6 pt-16 fw-medium">
        {{ $t.maintenance.ERR4xx.message }}
      </p>
      <cms-section v-if="$feature.enable404Espots && eSpots?.['shop-all']" :section="eSpots?.['shop-all']" />
    </div>
    <cms-section
      v-if="$feature.enable404Espots && eSpots?.['lazy-main']"
      :section="eSpots?.['lazy-main']"
      class="container"
    />
  </main>
</template>

<script lang="ts" setup>
const { $feature } = useNuxtApp()
const eSpots = (await useCms().getSections.error()).data.value?.content.sectionsMap
</script>
